import { route, prefixRoutes } from '@md/routing'
import installer from '@aspectus/vue-route-installer'

import { createRoutes as createDashboardRoutes } from './modules/dashboard/router'
import { createRoutes as createGroupRoutes } from './modules/group/router'
import { createRoutes as createSettingsRoutes } from './modules/settings/router'
import { createRoutes as createFaqRoutes } from './modules/faq/router'
import { createRoutes as createDocumentsRoutes } from './modules/documents/router'
import { createRoutes as createNotificationsRoutes } from './modules/notifications/router'
import { createRoutes as createPaymentRoutes } from './modules/payment/router'
import { createRoutes as createUsersRoutes } from './modules/users/router'
import { createRoutes as createOrdersRoutes } from './modules/orders/router'
import { createRoutes as createLessonRoutes } from './modules/lesson/router'
import { createRoutes as createHomeworkRoutes } from './modules/homework/router'
import { createRoutes as createFeedRoutes } from './modules/feed/router'
import { createRoutes as createNotFoundRoutes } from './modules/not-found/router'
import { createRoutes as createLiteratureRoutes } from './modules/literature/router'
import { createRoutes as createTestsRoutes } from './modules/tests-module/router'

const Profile = () => import('./views/Profile')
const ProfileInstaller = () => import('./components/Common')

export function createRoutes(options) {
  const { Vue } = options
  return [
    route('', installer(Profile, ProfileInstaller, Vue), null, {
      children: [
        prefixRoutes('dashboard/', createDashboardRoutes(options)),
        prefixRoutes('group/', createGroupRoutes(options)),
        prefixRoutes('faq/', createFaqRoutes(options)),
        prefixRoutes('document/', createDocumentsRoutes(options)),
        prefixRoutes('notification/', createNotificationsRoutes(options)),
        prefixRoutes('payment/', createPaymentRoutes(options)),
        prefixRoutes('users/', createUsersRoutes(options)),
        prefixRoutes('orders/', createOrdersRoutes(options)),
        prefixRoutes('lesson/', createLessonRoutes(options)),
        prefixRoutes('homework/', createHomeworkRoutes(options)),
        prefixRoutes('feed/', createFeedRoutes(options)),
        prefixRoutes('settings/', createSettingsRoutes(options)),
        prefixRoutes('literature/', createLiteratureRoutes(options)),
        // prefixRoutes('tests/', createTestsRoutes(options)),
        prefixRoutes('', createNotFoundRoutes(options)),
      ],
    }),
  ]
}
